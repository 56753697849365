
.left-border {
    border-left:1px solid #ddd !important
}

.unofficial-left-border {
    border-left:4px solid #a63860  !important
}

.unofficial-left-border2 {
    border-left:8px solid #a63860  !important
}