.perf-lt2to4 {
    background: #FFD400;
    color: #000;
}

.perf-lt4to6 {
    background: #F37520;
    color: #000;
}

.perf-lt6 {
    background: #ED1C24;
    color: #000;
}

.perf-gt2to4 {
    background: #BBE770;
    color: #000;
}

.perf-gt4to6 {
    background: #94D45C;
    color: #000;
}

.perf-gt6 {
    background: #72B700;
    color: #000;
}