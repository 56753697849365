@keyframes fade-background {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes pop-in {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.faded-background {
  background: rgba(255, 255, 255, 0.75);
  animation: fade-background 0.2s forwards ease-in-out;
}

.pop-in {
  animation: pop-in 0.2s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.hide-ugly-selection div[aria-selected="true"] {
  box-shadow: none !important;
  user-select: text;
}

.hide-ugly-selection .rdg-header-sort-cell>span:last-child {
  display: flex;
  align-items: center;
}

.hide-ugly-selection .rdg-header-sort-cell {
  user-select: none;
}


.screen-height-minus-navbar {
  max-height: calc(100vh - 7.5em);
}